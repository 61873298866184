<template>
  <a-layout>
    <a-layout-content>
      <a-empty description="Próximamente" />
      <a-button type="danger" @click.prevent="logout"> Salir </a-button>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Delivery",
  setup() {
    const store = useStore();
    function user() {
      return store.getters.user;
    }
    function logout() {
      store.dispatch("logout");
    }
    return { user, logout };
  },
};
</script>

<style scoped>
.ant-layout-content {
  height: 100vh;
  padding: 24px;
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>